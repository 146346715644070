import React from 'react';
import Helmet from 'react-helmet';

export default () => {
  return (
    <Helmet>
      <html lang="en" dir="ltr" />
      <title>Styli</title>
    </Helmet>
  );
};
