import React, { useState, useEffect } from 'react';
// import firebase from '@firebase/app';
// import '@firebase/auth';
// import { makeStyles } from '@material-ui/core/styles';
// import { initializeFirebase } from 'utils/';
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import Input from '../libs/Input/index';
import Button from '../libs/Buttons/index';

export default function Login({ ...props }) {
  // const [isAuth, setIsAuth] = useState(false);
  // const [showLoader, setShowLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // initializeFirebase();

  useEffect(() => {
    // firebase.auth().onAuthStateChanged(function(user) {
    //   setIsAuth(true);
    //   if (user) {
    //     console.log('yyyyyy');
    //     setIsLoggedIn(true);
    //   } else {
    //     console.log('xxxxxx');
    //     setIsLoggedIn(false);
    //   }
    // });
  }, []);
  const doLogin = async () => {
    //   setShowLoader(true);
    //   firebase
    //     .auth()
    //     .signInWithEmailAndPassword(email, password)
    //     .then(response => {
    //       console.log('login suceess: ', response);
    //       if (response.user) {
    //         setIsLoggedIn(true);
    //       }
    //       setShowLoader(false);
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //       // Handle Errors here.
    //       // var errorCode = error.code;
    //       // var errorMessage = error.message;
    //       // ...
    //     });
  };

  // const useStyles = makeStyles(theme => ({
  //   backdrop: {
  //     zIndex: theme.zIndex.drawer + 1,
  //     color: '#777',
  //     position: 'absolute',
  //     background: 'rgba(255,255,255,0.5)'
  //   }
  // }));
  // const classes = useStyles();

  return (
    // <Choose>
    // <When condition={isAuth}>
    <div {...props} className="ta-l pos-relt">
      {/* <Backdrop className={classes.backdrop} open={showLoader}>
            <CircularProgress color="primary" />
          </Backdrop> */}
      <div className="ta-l fs-25 fw-6 c-brown-gray">Please Login</div>
      <Input
        label="User Name"
        // floatLabel="dknvknd"
        placeholder="Enter User Name"
        labelClass="block ls-072 fs-13"
        wrapClassNames="mt-16"
        value={email}
        onChange={event => setEmail(event.target.value)}
        error=""
        dir="ltr"
        className={classnames('en-font ta-l')}
        type="email"
      />
      <Input
        label="Password"
        // floatLabel="dknvknd"
        placeholder="Enter Password"
        labelClass="block ls-072 fs-13"
        wrapClassNames="mt-16"
        value={password}
        onChange={event => {
          setPassword(event.target.value);
        }}
        error=""
        dir="ltr"
        className={classnames('en-font ta-l')}
        type="password"
      />
      <Button
        className="w100p primary cap fw-6 fs-14 lh-20 mt-25 b-none"
        onClick={doLogin}
      >
        Continue
      </Button>
    </div>
    //  </When>
    // <Otherwise>
    //   <div className="screen-fixed flex middle-xs center-xs">Loading...</div>
    // </Otherwise>
    // </Choose>
  );
}
