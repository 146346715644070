import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Logo from '../../assets/logo.svg';
import Login from '../../component/Login';

export default ({ is404 = false }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  console.log(setIsLoggedIn);
  const useStyles = makeStyles({
    box: {
      padding: '75px 100px 75px 100px',
      width: '40%'
    },
    appItem: {
      fontSize: '46px',
      height: '120px',
      width: '120px'
    }
  });

  const classes = useStyles();
  const history = useHistory();
  const handelAppClick = ({ target = '' }) => {
    const path = `/${target}/home`;
    history.push(path);
  };

  return (
    <>
      <div className="h100vh flex middle-xs center-xs">
        <div
          className={classnames(
            classes.box,
            'bg-white flex middle-xs between-xs center-xs b-light'
          )}
        >
          <Choose>
            <When condition={is404}>
              <div className="ta-c w100p">
                <div className="ta-c">
                  <Logo width="80" height="40" className="mb-16" />
                </div>
                <div className="c-brown-gray fs-50">404</div>
                <div className="c-dark-gray fs-25">Page Not Found</div>
                <div className="mt-20">
                  <Button variant="outlined" onClick={() => history.push('/')}>
                    GO TO HOME
                  </Button>
                </div>
              </div>
            </When>
            <When condition={isLoggedIn}>
              <div
                role="button"
                onClick={() => handelAppClick({ target: 'tracko' })}
                onKeyPress={() => handelAppClick('tracko')}
                className=""
              >
                <div
                  className={classnames(
                    classes.appItem,
                    'flex middle-xs center-xs c-white fw-6 bg-b1'
                  )}
                >
                  T
                </div>
                <div className={classnames('fs-21 fw-6 mt-16 c-b1')}>
                  TRACKO
                </div>
              </div>
              <div
                role="button"
                onClick={() => handelAppClick({ target: 'pickpro' })}
                onKeyPress={() => handelAppClick('pickpro')}
              >
                <div
                  className={classnames(
                    classes.appItem,
                    'flex middle-xs center-xs c-white fw-6 bg-b2'
                  )}
                >
                  P
                </div>
                <div className={classnames('fs-21 fw-6 mt-16 c-b1')}>
                  PICPRO
                </div>
              </div>
              <div
                role="button"
                onClick={() => handelAppClick({ target: 'codesk' })}
                onKeyPress={() => handelAppClick('codesk')}
              >
                <div
                  className={classnames(
                    classes.appItem,
                    'flex middle-xs center-xs c-white fw-6 bg-b3'
                  )}
                >
                  C
                </div>
                <div className={classnames('fs-21 fw-6 mt-16 c-b3')}>
                  CODESK
                </div>
              </div>
            </When>
            <Otherwise>
              <div className="flex center-xs middle-xs w100p be">
                <div className="w-400">
                  <Login setIsLoggedIn={setIsLoggedIn} />
                </div>
                <div className="mt-45 ml-auto">
                  <Logo width="150" height="" className="mb-16" />
                </div>
              </div>
            </Otherwise>
          </Choose>
        </div>
      </div>
    </>
  );
};
