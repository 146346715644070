import React from 'react';
import classnames from 'classnames';
import { Container } from '@material-ui/core';
import './index.scss';

export default function Button({ children, ...props }) {
  return (
    <div
      className={classnames('mt-88 pt-32 pb-15 ta-l c-dark-gray')}
      {...props}
    >
      <Container maxWidth={false}>{children}</Container>
    </div>
  );
}
