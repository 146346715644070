import React from 'react';
import Helmet from 'react-helmet';
import Suppliers from '../../container/Suppliers';
// import Wrapper from 'components/wrapper';
import Head from '../../component/libs/Head';

export default (...routeConfig) => {
  const [route] = routeConfig;
  const { history = false } = route;

  return (
    <>
      <Head {...routeConfig} />
      <Helmet>
        <title>Styli Account</title>
      </Helmet>
      {/* <Wrapper> */}
      <Suppliers history={history} />
      {/* </Wrapper> */}
    </>
  );
};
