import React from 'react';
import { Container } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Logo from '../../assets/logo.svg';

export default function Login({ ...props }) {
  return (
    <header {...props} className="bb-light top-fixed">
      <Container maxWidth={false}>
        <div className="flex">
          <div className="mr-auto flex middle-xs">
            <a>
              <Logo height="30px" width="60px" />
            </a>
            <div className="fs-14 fw-6 ls-096 c-brand caps ml-10">SUPPLIER</div>
          </div>
          <div className="ml-auto flex middle-xs">
            <div className="pl-20 pr-20 pt-15 pb-15 bl-light-gray br-light-gray">
              <NotificationsIcon className="c-dark-gray" fontSize="large" />
            </div>
            <div className="flex middle-xs pl-20 pr-20 pt-15 pb-15">
              <AccountCircleIcon className="c-dark-gray" fontSize="large" />
              <div className="pl-10 pr-20 c-brown-gray">User Name</div>
              <ExpandMoreIcon className="c-brown-gray" />
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
}
