import React from 'react';
import Header from '../../component/Header';
import Container from '../../component/libs/Container';
import Table from '../../component/libs/Table';

export default () => {
  // const [c, setRowData] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [page, setPage] = React.useState(0);

  const colData = [
    { id: 'fileName', label: 'File Name', minWidth: 100 },
    { id: 'errorMsg', label: 'Error Message', minWidth: 100 },
    { id: 'errorType', label: 'Error Type', minWidth: 100 },
    { id: 'createdAt', label: 'Occured At', minWidth: 100 }
  ];

  function createData(fileName, errorMsg, errorType, createdAt) {
    return { fileName, errorMsg, errorType, createdAt };
  }

  const rowData = [
    createData('Frozen yoghurt', 159, 6.0, 24),
    createData('Ice cream sandwich', 237, 9.0, 4.3),
    createData('Eclair', 262, 16.0, 24),
    createData('Cupcake', 305, 3.7, 67),
    createData('Gingerbread', 356, 16.0, 3.9)
  ];

  return (
    <>
      <Header />
      <Container>
        <div className="pl-25 pr-25 pt-32 pb-32 b-light">
          <div className="fs-20 fw-5">Rucy Paris Ruxin Clothing CO. LTD</div>
          <div className="row mt-30">
            <div className="col-xs-3">
              <div className="fs-14 c-brown-gray cap mb-8">PO#</div>
              <div className="fs-18 fw-5">7000012</div>
            </div>
            <div className="col-xs-3">
              <div className="fs-14 c-brown-gray cap mb-8">Release Date</div>
              <div className="fs-18 fw-5">7000012</div>
            </div>
            <div className="col-xs-3">
              <div className="fs-14 c-brown-gray cap mb-8">Packing List</div>
              <div className="fs-18 fw-5">7000012</div>
            </div>
            <div className="col-xs-3">
              <div className="fs-14 c-brown-gray cap mb-8">Consignor</div>
              <div className="fs-18 fw-5">7000012</div>
            </div>
          </div>
          <div className="row mt-30">
            <div className="col-xs-3">
              <div className="fs-14 c-brown-gray cap mb-8">InBound</div>
              <div className="fs-18 fw-5">1000</div>
            </div>
            <div className="col-xs-3">
              <div className="fs-14 c-brown-gray cap mb-8">Delivery Date</div>
              <div className="fs-18 fw-5">7000012</div>
            </div>
            <div className="col-xs-3">
              <div className="fs-14 c-brown-gray cap mb-8"># Of Cartons</div>
              <div className="fs-18 fw-5">20</div>
            </div>
            <div className="col-xs-3">
              <div className="fs-14 c-brown-gray cap mb-8">Gross Wt</div>
              <div className="fs-18 fw-5">20 Kilo</div>
            </div>
          </div>
        </div>
        <div className="pl-25 pr-25 pt-32 pb-32 b-light mt-25">
          <div className="fs-18 fw-5 mb-16">20 Items</div>
          <Table
            rowData={rowData}
            colData={colData}
            // rowsPerPage={rowsPerPage}
            // setRowsPerPage={setRowsPerPsage}
            // page={page}
            // setPage={setPage}
            // totalCount={trackoState.errorCount}
            // isLoading={trackoState.errorListLoading}
          />
        </div>
      </Container>
    </>
  );
};
