import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './styles/main.scss';
import Home from './pages/Home';
import Suppliers from './pages/Suppliers';
import { AppProvider } from './context/app';

function App() {
  return (
    <AppProvider>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/suppliers" exact component={Suppliers} />
          <Route path="*" exact component={() => <Home is404={true} />} />
        </Switch>
      </Router>
    </AppProvider>
  );
}

export default App;
