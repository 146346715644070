import React, { createContext, useContext } from 'react';

// import TrackoReducer, {
//   initialState as trackoInitialState
// } from "../containers/Tracko/reducer";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  return <AppContext.Provider>{children}</AppContext.Provider>;
};

export const useStateValue = () => useContext(AppContext);
